@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&display=swap');

body {
  font-family: 'Lato', sans-serif;
}

h1, h2, h3 {
  font-family: 'Dancing Script', cursive;
}

.app-background {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.207)), url('../public/grace-in-my-space.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.progress-bar {
  margin-bottom: 16px;
}

.logo-container img {
  width: 100%;
  height: auto;
  max-width: 300px;
  padding-top: 20px;
}

/* Responsive Typography Adjustments */
@media (max-width: 479px) {
  h2, h3 {
    font-size: 1.5rem; /* Adjust the size for smaller screens */
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  h2, h3 {
    font-size: 2rem; /* Slightly larger for tablets and larger mobile devices */
  }
  .logo-container img {
    max-width: 300px; /* Adjust logo size for smaller screens */
  }
}

@media (min-width: 768px) {
  .step-wrapper-container {
    padding: 0 50px;
  }
  .logo-container img {
    max-width: 300px; /* Adjust for larger tablets and small desktops */
  }
}

@media (min-width: 1200px) {
  .step-wrapper-container {
    padding: 0 80px;
  }
  .logo-container img {
    max-width: 300px; /* Default max size for large desktops */
  }
}

/* Ensure subtitles are responsive and legible across devices */
.subtitle1 {
  font-size: 0.875rem; /* Smaller text for subtitles on mobile */
}

@media (min-width: 480px) {
  .subtitle1 {
    font-size: 0.875rem; /* Standard subtitle size for tablets and upwards */
  }
}
